






import { useToolState } from '@/store';

export default {
  setup(props, ctx) {
    const { isLoading } = useToolState(['isLoading']);

    return { isLoading };
  }
};
